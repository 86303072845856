// @ts-nocheck
'use strict';
var Swiper = require('swiper');
var isMobile = !($(window).width() >= 768);

$('body').on('live:category-list', function() {
    var mySwipers = new Swiper.default(".js-category-list-init", {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: isMobile ? 7 : 10,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20
        // autoplay: {
        //     delay: 3000
        // },
        // If we need pagination
        // pagination: {
        //     el: '.js-category-list-init .swiper-pagination'
        // }
    });
});
