'use strict';

module.exports = {
    handleCategoryFilter: function () {
        $(document).on('click', '.js-category-item', function () {
            $('.js-category-item a').removeClass('selected');
            $(this).find('a').addClass('selected');
            var categoryId = $(this).data('cgid');
            if (categoryId && categoryId !== 'all') {
                $('.js-bundle-section').addClass('d-none');
                $('.js-product-tile').addClass('d-none');
                $('.js-product-tile.' + categoryId).removeClass('d-none');
            } else {
                $('.js-product-tile').removeClass('d-none');
                $('.js-bundle-section').removeClass('d-none');
            }
        });
    }
};
