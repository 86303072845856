/* eslint-disable no-mixed-operators */
'use strict';
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */

var currentDate = new Date();
var minAllowedYear = currentDate.getFullYear() - 100;

module.exports = {
    name$regex: /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Zàâçéèêëîïôûùüÿñæœáíóú]+(\s{0,1}[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Zàâçéèêëîïôûùüÿñæœáíóú, {2}])*$/i,
    // name$regex: /^[A-Za-z]+$/,
    email$regex: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,8}|[0-9]{1,3})(\]?)$/,

    quickButtonLoginEnable: function (elemForm, elemEmail, elemPassword, elemButton) {
        var $enoccode = $('.js-enoc-node-input').length > 0;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (($(elemEmail).val().length > 5 && $(elemEmail).valid()) && ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17)) {
                if ($enoccode) {
                    if ($('.js-enoc-node-input').val().length >= 3) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', true);
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    quickButtonInstantCashResent: function (elemForm, elemNumber, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (($(elemNumber).val().length > 8)) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    quickButtonLoginInstantcashEnable: function (elemForm, elemEmail, elemPassword, elemConfirmPassword, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (($(elemEmail).val().length > 5 && $(elemEmail).valid()) && ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) && ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) && $('#agreeTerms').prop('checked') === true) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                if (($(elemEmail).val().length > 5 && $(elemEmail).valid()) && ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) && ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid())) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    registerationButtonEnableMobile: function (elemForm, elemButton) {
        var $flagged = false;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                // if ($(this).val().length >= 2 && $('#agreeTerms').prop('checked') === true && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && ($('.jq-dte-year').val() > minAllowedYear) && $('#nationality').val() !== '') {
                    if ($(this).val().length >= 2 && $('#agreeTerms').prop('checked') === true) {   
                if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                    // if ($(this).val() >= 2 && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && ($('.jq-dte-year').val() > minAllowedYear) && $('#nationality').val() !== '') {
                        if ($(this).val() >= 2) {
                        if ($(elemForm).valid()) {
                            $(elemButton).prop('disabled', false);
                            $(elemButton).removeClass('is-disabled');
                        } else {
                            $(elemButton).prop('disabled', 'disabled');
                            $(elemButton).addClass('is-disabled');
                        }
                    }
                });
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $('#nationality,#countryResicence,input:radio').on('change', function () {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 2 && $('#agreeTerms').prop('checked') === true && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && ($('.jq-dte-year').val() > minAllowedYear)) {
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });
    },
    partnerregisterationButtonEnableMobile: function (elemForm, elemFirstName, elemLastName, elemEmail, elemPhone, elemPassword, elemConfirmPassword, elemCode, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            // console.log($(elemEmail).val().length > 5 && $(elemEmail).valid());
            // console.log($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid());
            // console.log($(elemCode).val() >= 3);
            // console.log($(elemPhone).val());
            // console.log($('#agreeTerms').prop('checked') === true);
            if (
                ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                ($(elemEmail).val().length > 5 && $(elemEmail).valid()) &&
                ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) &&
                ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) &&
                ($(elemCode).val().length >= 3) &&
                ($(elemPhone).val().length > 5) &&
                $('#agreeTerms').prop('checked') === true
            ) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                if (
                    ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                    ($(elemEmail).val().length > 5 && $(elemEmail).valid()) &&
                    ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) &&
                    ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) &&
                    ($(elemCode).val().length >= 3) &&
                    ($(elemPhone).val().length > 5)
                ) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    verifyClaimBtnEnable: function (elemForm, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {

                if ($(this).val().length >= 2 && $('#agreeTerms').prop('checked') === true) {
                    if ($(elemForm).valid()) {
                        console.log('checked');
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                    if ($(this).val().length >= 2) {
                        if ($(elemForm).valid()) {
                            $(elemButton).prop('disabled', false);
                            $(elemButton).removeClass('is-disabled');
                        } else {
                            $(elemButton).prop('disabled', 'disabled');
                            $(elemButton).addClass('is-disabled');
                        }
                    }
                });
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    landingRegisterMobile: function (elemForm, elemFirstName, elemLastName, elemEmail, elemPhone, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (
                ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                ($(elemEmail).val().length > 5 && $(elemEmail).valid()) &&
                ($(elemPhone).val().length > 5) &&
                $('#agreeTerms').prop('checked') === true
            ) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                if (
                    ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                    ($(elemEmail).val().length > 5 && $(elemEmail).valid()) &&
                    ($(elemPhone).val().length > 5)
                ) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    registerationButtonEnableMobileQuickCheckout: function (elemForm, elemButton) {
        var $flagged = false;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 3) {
                    if ($(elemForm).valid()) {
                        alert('yes');
                    } else {
                        alert('no');
                    }
                }
            });
        });
    },
    forgotPasswordbuttonEnable: function (elemForm, elemEmail, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if ($(elemEmail).val().length > 5 && $(elemEmail).valid()) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    registerationButtonEnable: function (form, otp, mobileNumber, mobileBtn, terms, elemButton) {
        $(mobileNumber).on('keyup', function () {
            if ($(this).val().length > 4 && $(this).val().length < 13) {
                $(mobileBtn).prop('disabled', false);
                $(mobileBtn).removeClass('is-disabled');
                $(otp).val('');
            } else {
                $(mobileBtn).prop('disabled', true);
                $(mobileBtn).addClass('is-disabled');
                $(otp).val('');
                $(otp).removeClass('valid');
                $(otp).parent().addClass('d-none');
            }
        });
        $(otp).on('keyup', function (event) {
            if ($(this).val().length >= 4 && $(terms).is(':checked')) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
        $(terms).change(function () {
            if (this.checked && $(otp).val().length >= 4 && $(form).valid()) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
        
    },
    updateProfileEnableButton: function (elemForm, firstname, lastname, mobilenumber, dateofbirth, countryCodeSelector, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val().length >= 2 && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && ($('.jq-dte-year').val() > minAllowedYear) && $('#nationality').val() !== '') {
                    if ($(elemForm).find('.form-control-label.invalid').length === 0) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                        return false;
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                    return false;
                }
                if ($(mobilenumber).val() === '' || $(mobilenumber).val().length < 8) {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                    return false;
                }
            });
        });

        $('#nationality,#countryResicence,input:radio').on('change', function () {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val().length >= 2 && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && ($('.jq-dte-year').val() > minAllowedYear)) {
                    if ($(elemForm).find('.form-control-label.invalid').length === 0) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                        return false;
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                    return false;
                }
                if ($(mobilenumber).val() === '' || $(mobilenumber).val().length < 8) {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                    return false;
                }
            });
        });
        $(countryCodeSelector).on('change', function (e) {
            $(elemButton).prop('disabled', false);
            $(elemButton).removeClass('is-disabled');
        });
    },
    changePasswordEnableButton: function (form, current, newpass, confirmpass, button) {
        $(form).find('input').on('keyup', function () {
            if (
                $(current).val().length >= 8 && $(newpass).val().length >= 8 && $(confirmpass).val().length >= 8 && $(current).val() !== $(newpass).val() && $(newpass).val() === $(confirmpass).val()
            ) {
                $(form).valid();
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
    },
    resetPasswordNew: function (form, newpass, confirmpass, button) {
        $(form).find('input').on('keyup', function () {
            if (
                $(newpass).val().length >= 8 && $(confirmpass).val().length >= 8 && $(newpass).val() === $(confirmpass).val()
            ) {
                $(form).valid();
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
    },
    contactUsFormEnableButton: function (form, name, email, message, button) {
        $(form).find('input, textarea').on('keyup', function () {
            if ($(name).val().length >= 2 && $(name).valid() && $(email).val().length >= 5 && $(email).valid() && $(message).val().length >= 15) {
                $(form).valid();
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
    }
};
