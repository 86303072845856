// @ts-nocheck
'use strict';
var Swiper = require('swiper');

$('body').on('live:bundleLoaded', function() {
    var soldoutSlider = new Swiper.default('.js-bundle-swiper-init', {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 18,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 21,
        preloadImages: false,
        watchSlidesVisibility: true,
        freeMode: true,
        pagination: {
            dynamicBullets: true,
            el: ".js-bundle-swiper-init .swiper-pagination",
            clickable: true,
          },
        navigation: {
            nextEl: '.bundle-swiper-container .swiper-button-next',
            prevEl: '.bundle-swiper-container .swiper-button-prev',
        },
        // Enable lazy loading
    });
})
