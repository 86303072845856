/* eslint-disable no-undef */
'use strict';

var toastr = require('../components/toastr');
// var braze = require('../braze');
var createErrorNotification = require('base/components/errorNotification');
var errorLabel = require('../errorLabels');
var isMobile = !($(window).width() >= 768);
var globalRegister = false;
var CUSTOM_MOENGAGE = require('../moengage');
/**
 * Showing Register screen.
 * @constructor
 */
function registerScreen() {
    $('.login-box').fadeOut(100, function () {
        $('.register-box').fadeIn(100);
    });
}

/**
 * Showing Login screen.
 * @constructor
 */
function loginScreen() {
    $('.register-box').fadeOut(100, function () {
        $('.login-box').fadeIn(100);
        $('.new-login-tabs li').removeClass('active');
        $('.new-login-tabs li:first-child').addClass('active');
    });
    $('html, body').animate({ scrollTop: $('.register-box').offset().top - 100 }, 100);
}

/**
 * Represents a sum.
 * @constructor
 * @param {dom} e the clicked element.
 */
function goBackScreen(e) {
    var $this = e;
    var $parent = $this.parent().parent().parent();
    if ($parent.hasClass('forgot-password')) {
        $('.forgot-password').fadeOut(100, function () {
            $('.login-box').fadeIn(100);
            $('#forgot-password-form input').val('');
            $('#forgot-password-form label').removeClass('active');
            $('#btn-forgot-password').addClass('is-disabled');
        });
    } else if ($parent.hasClass('login-box')) {
        $('.js-trigger-navigation').trigger('click');
    } else if ($parent.is('#registeration-step-1')) {
        loginScreen();
    } else if ($parent.is('#registeration-step-2')) {
        $('#registeration-step-2').fadeOut(50, function () {
            $('#registeration-step-1').fadeIn(100);
            $('#registeration-profile-pic').fadeIn(100);
        });
    } else if ($parent.hasClass('forgot-password-success')) {
        if (isMobile) {
            $('.forgot-password-success').fadeOut(100, function () {
                $('.login-box').fadeIn(100);
            });
        } else {
            $('.forgot-password-success').fadeOut();

            $(setTimeout(function () {
                $('.login-box').fadeIn();
                $('.login-area-wrapper > .js-main-title').fadeIn();
            }, 400));
        }
    }
}

/**
 * Represents a sum.
 * @constructor
 * @param {string} e the msg for successful send.
 */
function forgotPasswordSuccess(e) {
    if (isMobile) {
        $('.forgot-password').fadeOut(100, function () {
            $('.forgot-password-success p').text(e);
            $('.forgot-password-success').fadeIn(100);
        });
    } else {
        $('.forgot-password').fadeOut();
        $(setTimeout(function () {
            $('.forgot-password-success p').text(e);
            $('.forgot-password-success').fadeIn(100);
        }, 400));
    }
}

/**
 * Go to forgot password
 * @constructor
 */
function forgetPasswordTrigger() {
    $('.login-box').fadeOut(100, function () {
        $('.forgot-password').fadeIn(100);
    });
}

/**
 * Go to forgot password
 * @constructor
 */
function forgetPasswordTriggerDesktop() {
    if (isMobile) {
        $('.forgot-password').fadeIn();
    } else {
        $('.login-box').fadeOut();
        $('.login-area-wrapper > .js-main-title').fadeOut();
        $(setTimeout(function () {
            $('.forgot-password').fadeIn();
        }, 400));
    }
    $('.login-profile-selection').addClass('d-none');
}
/**
 * Go to forgot password
 * @constructor
 */
function closeModalBoxTrigger() {
    $('.modalBox.forgotBox').fadeOut();
}

module.exports = {
    mobileTabs: function () {
        $(document).on('click', '.new-login-tabs a', function (e) {
            var $this = $(this);
            var $box = $this.data('open');
            $this.parent().addClass('active').siblings().removeClass('active');

            if ($box === 'login-box') {
                $('.login-box').show();
                $('.register-box').hide();
            } else {
                $('.login-box').hide();
                $('.register-box').show();
            }
        });
    },

    login: function () {
        $('#login-registration').validate({
            rules: {
                loginEmail: {
                    required: true,
                    email: false,
                    regex: errorLabel.email$regex
                },
                loginPassword: {
                    required: true,
                    minlength: 8,
                    maxlength: 17
                },
                enocCode: {
                    required: true,
                    minlength: 3,
                }
            },
            messages: {
                loginEmail: {
                    regex: resources.Global$invalidEmail
                },
                loginPassword: {
                    minlength: resources.Global$passwordMin,
                    maxlength: resources.Global$passwordMax,
                    required: resources.Global$passwordRequired
                },
                enocCode : {
                    required: resources.Global$fieldRequired
                }
            },
            submitHandler: function (gform, e) {
                var currentPage = $('#theme-color-top').html();
                // do other things for a valid form
                var gTagData;
                var form = $(gform);
                e.preventDefault();
                var url = form.attr('action');
                form.spinner().start();
                $('form.login').trigger('login:submit', e);
                var formData = form.serialize();
                // if it's  enoc form submit, strcture the data accordingly
                if (form.hasClass('js-enoc-login-form')) {
                    var enocCodes = [];
                    // formData.delete('enocCode');

                    // build array for all the codes used
                    $('.js-enoc-login-form').find('input[name="enocCode"').each(function () {
                        if ($(this).val() !== '') {
                            enocCodes.push($(this).val());
                        }
                    });
                    formData += '&enocCodes=' + JSON.stringify(enocCodes);
                }

                if (currentPage === 'zoomLanding' || currentPage === 'dfcStage' || currentPage === 'modeshRetail' || currentPage === 'chattels') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                            formData = formData + '&token=' + token;
                            $.ajax({
                                url: url,
                                type: 'post',
                                dataType: 'json',
                                data: formData,
                                success: function (data) {
                                    // form.spinner().stop();
                                    gTagData = {
                                        email: $(form).find('[name="loginEmail"]')[0].value
                                    };
                                    if (data && data.customerdataMoE) {
                                        gTagData = Object.assign({}, gTagData, data.customerdataMoE, { dateOfBirth: data.customerdataMoE.dob });
                                    }
                                    if (!data.success) {
                                        form.spinner().stop();
                                        if (currentPage === 'zoomLanding') {
                                            alert(data.message);
                                            gTagData.error = JSON.stringify(data);
                                        } else {
                                            toastr(data, data.error);
                                            gTagData.error = JSON.stringify(data.error);
                                        }
                                        $(document).trigger('login:error', gTagData);
                                    } else {
                                        $(document).trigger('login:success', gTagData);
                                        if (typeof Moengage !== 'undefined' && Moengage) {
                                            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.loginSuccess, gTagData, data.redirectUrl);
                                        } else {
                                            location.href = data.redirectUrl;
                                        }
                                    }
                                },
                                error: function (data) {
                                    if (data.responseJSON.redirectUrl) {
                                        window.location.href = data.responseJSON.redirectUrl;
                                    } else {
                                        form.spinner().stop();
                                        alert(data.responseJSON.message);
                                    }
                                }
                            });
                        });
                    });
                } else {
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: formData,
                        success: function (data) {
                            // form.spinner().stop();
                            gTagData = {
                                email: $(form).find('[name="loginEmail"]')[0].value
                            };
                            if (data && data.customerdataMoE) {
                                gTagData = Object.assign({}, gTagData, data.customerdataMoE, { dateOfBirth: data.customerdataMoE.dob });
                            }
                            if (!data.success) {
                                form.spinner().stop();
                                if (currentPage === 'zoomLanding') {
                                    alert(data.message);
                                    gTagData.error = JSON.stringify(data);
                                } else {
                                    toastr(data, data.error);
                                    gTagData.error = JSON.stringify(data.error);
                                }
                                $(document).trigger('login:error', gTagData);
                            } else {
                                $(document).trigger('login:success', gTagData);
                                if (typeof Moengage !== 'undefined' && Moengage) {
                                    CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.loginSuccess, gTagData, data.redirectUrl);
                                } else {
                                    location.href = data.redirectUrl;
                                }
                            }
                        },
                        error: function (data) {
                            if (data.responseJSON.redirectUrl) {
                                window.location.href = data.responseJSON.redirectUrl;
                            } else {
                                form.spinner().stop();
                                alert(data.responseJSON.message);
                            }
                        }
                    });
                }
                return false;
            }
        });

        errorLabel.quickButtonLoginEnable('#login-registration', '#login-registration #login-form-email', '#login-registration #login-form-password', '#login-registration .js-form-submit');
    },
    channelLogin: function () {
        $('#channel-login-registration').validate({
            rules: {
                loginEmail: {
                    required: true,
                    email: false,
                    regex: errorLabel.email$regex
                },
                loginPassword: {
                    required: true,
                    minlength: 8,
                    maxlength: 17
                }
            },
            messages: {
                loginEmail: {
                    regex: resources.Global$invalidEmail
                },
                loginPassword: {
                    minlength: resources.Global$passwordMin,
                    maxlength: resources.Global$passwordMax,
                    required: resources.Global$passwordRequired
                }
            },
            submitHandler: function (gform, e) {
                var currentPage = $('#theme-color-top').html();
                // do other things for a valid form
                var gTagData;
                var form = $(gform);
                e.preventDefault();
                var url = form.attr('action');
                form.spinner().start();
                $('form.login').trigger('login:submit', e);
                var formData = form.serialize();
                grecaptcha.ready(function () {
                    grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                        formData = formData + '&token=' + token;
                        $.ajax({
                            url: url,
                            type: 'post',
                            dataType: 'json',
                            data: formData,
                            success: function (data) {
                                // form.spinner().stop();
                                gTagData = {
                                    email: $(form).find('[name="loginEmail"]')[0].value
                                };
                                if (data && data.customerdataMoE) {
                                    gTagData = Object.assign({}, gTagData, data.customerdataMoE, { dateOfBirth: data.customerdataMoE.dob });
                                }
                                if (!data.success) {
                                    form.spinner().stop();
                                        toastr(data, data.error);
                                        gTagData.error = JSON.stringify(data.error);
                                    $(document).trigger('login:error', gTagData);
                                } else {
                                    $(document).trigger('login:success', gTagData);
                                    $('.login-customer').addClass('show');
                                }
                            },
                            error: function (data) {
                                if (data.responseJSON && data.responseJSON.redirectUrl) {
                                    window.location.href = data.responseJSON.redirectUrl;
                                } else {
                                    form.spinner().stop();
                                    alert(data.responseJSON.message);
                                }
                            }
                        });
                    });
                });
                
                return false;
            }
        });

        errorLabel.quickButtonLoginEnable('#channel-login-registration', '#channel-login-registration #login-form-email', '#channel-login-registration #login-form-password', '#channel-login-registration .js-form-submit');
    },

    instantCashResetn: function () {
        $('#instantcash-reset-form').validate();

        $('#phonenumber').rules('add', {
            required: true,
            maxlength: 12,
            minlength: 5,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });

        errorLabel.quickButtonInstantCashResent('#instantcash-reset-form', '#instantcash-reset-form #phonenumber', '#instantcash-reset-form .js-resent-instant-cash');
    },

    quickLogin: function () {
        $('.instantcash-register-form').validate({
            rules: {
                dwfrm_profile_customer_email: {
                    required: true,
                    email: false,
                    regex: errorLabel.email$regex
                },
                dwfrm_profile_login_password: {
                    required: true,
                    minlength: 8,
                    maxlength: 17
                },
                dwfrm_profile_login_passwordconfirm: {
                    required: true,
                    equalTo: '#password',
                    minlength: 8,
                    maxlength: 17
                }
            },
            messages: {
                dwfrm_profile_customer_email: {
                    regex: resources.Global$invalidEmail
                },
                dwfrm_profile_login_password: {
                    minlength: resources.Global$passwordMin,
                    maxlength: resources.Global$passwordMax,
                    required: resources.Global$passwordRequired
                },
                dwfrm_profile_login_passwordconfirm: {
                    required: resources.Global$passwordRequired,
                    equalTo: resources.Global$passwordequalTo,
                    minlength: resources.Global$passwordMin,
                    maxlength: resources.Global$passwordMax
                }
            }
        });

        errorLabel.quickButtonLoginInstantcashEnable('.instantcash-register-form', '.instantcash-register-form #email', '.instantcash-register-form #password', '.instantcash-register-form #registration-form-password-confirm', '.instantcash-register-form .mobile-button');
    },

    handleInstantCashReset: function () {
        $(document).on('submit', '.instantcash-reset-form', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $url = $this.attr('action');
            var $formData = new FormData();
            $formData.append('countryCode', $('#countryCode').val());
            $formData.append('phonenumber', $('#phonenumber').val());
            $formData.append('orderNum', $('.orderNumber').val());
            $formData.append('orderToken', $('.orderToken').val());
            var queryString = new URLSearchParams($formData).toString();
            grecaptcha.ready(function () {
                grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                    queryString = queryString + '&token=' + token;
                    $.ajax({
                        url: $url,
                        type: 'post',
                        dataType: 'json',
                        data: queryString,
                        success: function (data) {
                            // form.spinner().stop();
                            if (!data.success) {
                                toastr({ success: false }, data.message);
                            } else {
                                toastr({ success: true }, data.message);
                                $('#phonenumber').val('');
                            }
                        },
                        error: function (err) {
                            toastr({ success: false }, err.message);
                        }
                    });
                });
            });
        });
    },

    registerPartner: function () {
        $('#partner-register').validate();

        $('#partner-register #registration-form-fname').rules('add', {
            required: true,
            onlySpace: '#partner-register #registration-form-fname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                required: resources.Global$firstnameRequired,
                minlength: resources.Global$firstnameMin,
                regex: resources.Global$invalidName,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#partner-register #registration-form-lname').rules('add', {
            required: true,
            onlySpace: '#partner-register #registration-form-lname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                regex: resources.Global$invalidName,
                minlength: resources.Global$lastnameMin,
                required: resources.Gloabl$lastnameRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#partner-register #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });

        $('#partner-register .js-phone-mobile').rules('add', {
            required: true,
            maxlength: 12,
            minlength: 5,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });

        $('#partner-register #registration-form-password').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax
            }
        });

        $('#partner-register #registration-form-confirm-password').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            equalTo: '#registration-form-password',
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax,
                equalTo: resources.Global$passwordequalTo
            }
        });

        $('#partner-register').submit(function (e) {
            globalRegister = true;
            var form = $(this);
            var gTagData;
            e.preventDefault();
            var url = form.attr('action');
            $('form.registration').trigger('login:register', e);
            var countryCode = $(form).find('.country-code-container .custom-select span:first').text();
            if ($('input#agreeTerms').is(':checked')) {
                form.spinner().start();
                var formDataSerialized = form.serialize();
                // if it's  enoc form submit, strcture the data accordingly
                if (form.hasClass('js-enoc-register-form')) {
                    var enocCodes = [];
                    // formData.delete('enocCode');

                    // build array for all the codes used
                    $('.js-enoc-register-form').find('input[name="enocCode"').each(function () {
                        if ($(this).val() !== '') {
                            enocCodes.push($(this).val());
                        }
                    });
                    formDataSerialized += '&enocCodes=' + JSON.stringify(enocCodes);
                }
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: formDataSerialized + '&isMobile=' + isMobile,
                    success: function (data) {
                        gTagData = {
                            email: $(form).find('#registration-form-email')[0].value,
                            firstName: $(form).find('#registration-form-fname')[0].value,
                            lastName: $(form).find('#registration-form-lname')[0].value,
                            gender: $(form).find('.radio-button-group .active').attr('data-type'),
                            countryCode: countryCode !== '' ? countryCode : $('.mobileNmuber .custom-select span:first').text(),
                            mobileNumber: $(form).find('.js-phone-mobile')[0].value
                        };
                        if (!data.success) {
                            form.spinner().stop();
                            gTagData.error = JSON.stringify(data.fields);
                            $(document).trigger('register:error', gTagData);
                            toastr(data, data.fields);
                            globalRegister = false;
                        } else {
                            $(document).trigger('register:success', gTagData);
                            if (typeof data.isMiniRegister !== 'undefined' && data.isMiniRegister) {
                                var $parentRegisterBanner = $('.registerSuccessPop');
                                if (data.promoRegisterIpointsEnable && data.promoRegisterIpointsValues > 0) {
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').text(data.promoMessage);
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').show();
                                } else {
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').hide();
                                }
                                // js-ipoints-promo-text

                                $('#registeration-step-1, #registeration-step-2, .mini-registration-header, .nomargi-flex').fadeOut(100, function () {
                                    $parentRegisterBanner.fadeIn(100);
                                });

                                $parentRegisterBanner.find('.js-register-redirect').attr('href', data.redirectUrl);
                                // $parentRegisterBanner.show();
                                form.spinner().stop();
                                // hnadle braze events
                                // braze.registerUserOnBrazeMiniPopup(data, isMobile);
                            } else {
                                location.href = data.redirectUrl;
                            }
                        }
                        globalRegister = false;
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                        }
                        globalRegister = false;
                        form.spinner().stop();
                    }
                });
            } else {
                alert(resources.Gloabl$agreeTerms);
            }

            return false;
        });


        // $('#registration-form-password-confirm').rules('add', {
        //     required: true,
        //     equalTo: '#registration-form-password',
        //     maxlength: 17,
        //     messages: {
        //         required: resources.Global$passwordRequired,
        //         equalTo: resources.Global$passwordequalTo,
        //         minlength: resources.Global$passwordMin,
        //         maxlength: resources.Global$passwordMax
        //     }
        // });


        errorLabel.partnerregisterationButtonEnableMobile('#partner-register', '#partner-register #registration-form-fname', '#partner-register #registration-form-lname', '#partner-register #registration-form-email', '#partner-register .js-phone-mobile', '#partner-register #registration-form-password', '#partner-register #registration-form-confirm-password', '#partner-register .enoc-code input', '#partner-register .enoc-button');
    },

    landingRegister: function () {
        $('#landing-form').validate();

        $('#landing-form #registration-form-fname').rules('add', {
            required: true,
            onlySpace: '#landing-form  #registration-form-fname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                required: resources.Global$firstnameRequired,
                minlength: resources.Global$firstnameMin,
                regex: resources.Global$invalidName,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#landing-form #registration-form-lname').rules('add', {
            required: true,
            onlySpace: '#landing-form #registration-form-lname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                regex: resources.Global$invalidName,
                minlength: resources.Global$lastnameMin,
                required: resources.Gloabl$lastnameRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#landing-form #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });

        $('#landing-form .js-phone-mobile').rules('add', {
            required: true,
            maxlength: 12,
            minlength: 5,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });
        $('#landing-form #registration-form-password').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax
            }
        });
        $('#landing-form #registration-form-password-confirm').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            equalTo: '#registration-form-password',
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax,
                equalTo: resources.Global$passwordequalTo
            }
        });
        $('#landing-form #registration-enoc-code').rules('add', {
            required: true,
            minlength: 3
        });

        function getFormData($form){
            var unindexed_array = $form.serializeArray();
            var indexed_array = {};
        
            $.map(unindexed_array, function (n, i) {
                n['value'] = n['value'].trim();
                if (n['value']) {
                    if (n['name'].indexOf('[]') !== -1) {
                        if (indexed_array[n['name'].replace('[]', '')] === undefined) {
                            indexed_array[n['name'].replace('[]','')] = [];
                        }
                        indexed_array[n['name'].replace('[]','')].push(n['value']);
                    } else {
                        indexed_array[n['name']] = n['value'];
                    }
                }
            });
        
            return JSON.stringify(indexed_array);
        }


        $('#landing-form').submit(function (e) {
            var currentPage = $('#theme-color-top').html();
            e.preventDefault();
            var form = $(this);
            var gTagData;
            if ($('input#agreeTerms').is(':checked')) {
                // calling ajax call to registered customer
                var data = $(this).serialize();
                if ($('.js-zoom-landing').length) {
                    data = { landingData: getFormData($(this)) };
                }
                var url = $(this).attr('action');
                var countryCode = $(form).find('.country-code-container .custom-select span:first').text();
                $(this).spinner().start();
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        $(this).spinner().stop();
                        gTagData = {
                            email: $(form).find('#registration-form-email')[0].value,
                            firstName: $(form).find('#registration-form-fname')[0].value,
                            lastName: $(form).find('#registration-form-lname')[0].value,
                            countryCode: countryCode !== '' ? countryCode : $('.mobileNmuber .custom-select span:first').text(),
                            mobileNumber: $(form).find('.js-phone-mobile')[0].value,
                            origin: $(form).find('input[name="campaignType"]')[0].value,
                            userID: response.customerNo ? response.customerNo : null,
                        };
                        if (!response.success) {
                            gTagData.error = JSON.stringify(response.fields);
                            $(document).trigger('register:error', gTagData);
                            alert(response.message);
                        } else {
                            $(document).trigger('register:success', gTagData);
                            if((currentPage === 'zoomLanding' || currentPage === 'dfcStage' || currentPage === 'modeshRetail' || currentPage === 'chattels') && response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            } else {
                                 $('.customer-registered').addClass('show');
                            }
                        }
                        // consoloe.log(response);
                    },
                    error: function (data) {
                        if (data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                        } else {
                            $(this).spinner().stop();
                            alert(data.responseJSON.message);
                        }
                    }

                });
            } else {
                alert(resources.Gloabl$agreeTerms);
                $(this).spinner().stop();
            }
        });

        // errorLabel.landingRegisterMobile('#landing-form', '#landing-form #registration-form-fname', '#landing-form #registration-form-lname', '#landing-form #registration-form-email', '#landing-form .js-phone-mobile', '#landing-form .js-landing-button');
    },
    verifyClaim: function () {
        $('#verify-otp').validate();
        $('#verify-otp #registration-form-fname').rules('add', {
            required: true,
            onlySpace: '#verify-otp #registration-form-fname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                required: resources.Global$firstnameRequired,
                minlength: resources.Global$firstnameMin,
                regex: resources.Global$invalidName,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#verify-otp #registration-form-lname').rules('add', {
            required: true,
            onlySpace: '#verify-otp #registration-form-lname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                regex: resources.Global$invalidName,
                minlength: resources.Global$lastnameMin,
                required: resources.Gloabl$lastnameRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#verify-otp #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });


        // $('#verify-otp').submit(function (e) {
        //     e.preventDefault();
        //     var $form = $(this);
        //     $.ajax({
        //         url: $form.attr('action'),
        //         type: 'post',
        //         dataType: 'json',
        //         data: $form.serialize(),
        //         success: function (data) {
        //             if (!data.success) {
        //                 // eslint-disable-next-line no-alert
        //                 alert(data.message);
        //             } else {
        //                 window.location.href = data.redirectUrl;
        //             }
        //         }
        //     });
        // });
        $('#verify-otp').submit(function (e) {
            var currentPage = $('#theme-color-top').html();
            e.preventDefault();
            var form = $(this);
            var gTagData;
            if ($('input#agreeTerms').is(':checked')) {
                // calling ajax call to registered customer
                var data = $(this).serialize();
                var url = $(this).attr('action');
                var countryCode = $(form).find('.country-code-container .custom-select span:first').text();
                $(this).spinner().start();
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        $(this).spinner().stop();
                        gTagData = {
                            email: $(form).find('#registration-form-email')[0].value,
                            firstName: $(form).find('#registration-form-fname')[0].value,
                            lastName: $(form).find('#registration-form-lname')[0].value,
                            // countryCode: countryCode !== '' ? countryCode : $('.mobileNmuber .custom-select span:first').text(),
                            mobileNumber: $(form).find('.js-phone-mobile').text(),
                            origin: $(form).find('input[name="campaignType"]')[0].value,
                            userID: response.customerNo ? response.customerNo : null,
                        };
                        if (!response.success) {
                            gTagData.error = JSON.stringify(response.fields);
                            $(document).trigger('register:error', gTagData);
                            alert(response.message);
                        } else {
                            $(document).trigger('register:success', gTagData);
                            window.location.href = response.redirectUrl;
                        }
                        // consoloe.log(response);
                    },
                    error: function (data) {
                        if (data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                        } else {
                            $(this).spinner().stop();
                            alert(data.responseJSON.message);
                        }
                    }

                });
            } else {
                alert(resources.Gloabl$agreeTerms);
                $(this).spinner().stop();
            }
        });


        // errorLabel.verifyClaimBtnEnable('#verify-otp', '#verify-otp #js-form-submit');
    },
    confirmPasswords: function () {
        $('#registeration-form-password').validate();

        $('#registeration-form-password #registration-form-password').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax
            }
        });

        $('#registeration-form-password #registration-form-confirm-password').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            equalTo: '#registration-form-password',
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax,
                equalTo: resources.Global$passwordequalTo
            }
        });

        $('#registeration-form-password').submit(function (e) {
            if ($(this).valid()) {
                e.preventDefault();
                $(this).spinner().start();
                var url = $(this).attr('action');
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: $(this).serialize(),
                    success: function (data) {
                        $(this).spinner().stop();
                        if (data.success) {
                            window.location.href = data.redirectUrl;
                        } else {
                            alert(data.message);
                        }
                    }
                });
            }
        });
    },

    register: function () {
        $('#registeration-form').validate();

        $('#registeration-form').submit(function (e) {
            globalRegister = true;
            var form = $(this);
            var gTagData;
            e.preventDefault();
            var url = form.attr('action');

            $('form.registration').trigger('login:register', e);
            var countryCode = $(form).find('.country-code-container .custom-select span:first').text();
            if ($('input#agreeTerms').is(':checked')) {
                form.spinner().start();
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize() + '&isMobile=' + isMobile,
                    success: function (data) {
                        gTagData = {
                            email: $(form).find('#registration-form-email')[0].value,
                            firstName: $(form).find('#registration-form-fname')[0].value,
                            lastName: $(form).find('#registration-form-lname')[0].value,
                            gender: $(form).find('.gender-container  input[type=radio]:checked').val(),
                            countryCode: countryCode !== '' ? countryCode : $('.mobileNmuber .custom-select span:first').text(),
                            mobileNumber: $(form).find('.js-phone-mobile')[0].value,
                            // dateOfBirth: $(form).find('#birthday')[0].value,
                            // nationality: $(form).find('#nationality').val(),
                            userID: data.userID
                        };
                        if (!data.success) {
                            form.spinner().stop();
                            gTagData.error = JSON.stringify(data.fields);
                            $(document).trigger('register:error', gTagData);
                            toastr(data, data.fields);
                        } else {
                            $(document).trigger('register:success', gTagData);
                            if (typeof data.isMiniRegister !== 'undefined' && data.isMiniRegister) {
                                var $parentRegisterBanner = $('.registerSuccessPop');
                                if (data.promoRegisterIpointsEnable && data.promoRegisterIpointsValues > 0) {
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').text(data.promoMessage);
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').show();
                                } else {
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').hide();
                                }
                                // js-ipoints-promo-text

                                $('#registeration-step-1, #registeration-step-2, .mini-registration-header, .nomargi-flex').fadeOut(100, function () {
                                    $parentRegisterBanner.fadeIn(100);
                                });

                                $parentRegisterBanner.find('.js-register-redirect').attr('href', data.redirectUrl);
                                // $parentRegisterBanner.show();
                                form.spinner().stop();
                                // handle braze events
                                // braze.registerUserOnBrazeMiniPopup(data, isMobile);
                            } else {
                                $('.get-started-button').attr('href', data.redirectUrl);
                                $('.register-overlay').addClass('show');
                                $(".singleOtp").blur();
                                form.spinner().stop();
                            }
                        }
                        globalRegister = false;
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                        }
                        globalRegister = false;
                        form.spinner().stop();
                    }
                });
            } else {
                alert(resources.Gloabl$agreeTerms);
            }

            return false;
        });

        $('#registeration-form #registration-form-fname').rules('add', {
            required: true,
            onlySpace: '#registration-form-fname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                required: resources.Global$firstnameRequired,
                minlength: resources.Global$firstnameMin,
                regex: resources.Global$invalidName,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#registeration-form #registration-form-lname').rules('add', {
            required: true,
            onlySpace: '#registration-form-lname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                regex: resources.Global$invalidName,
                minlength: resources.Global$lastnameMin,
                required: resources.Gloabl$lastnameRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#registeration-form #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });

        $('#registeration-form #registration-form-phoneMobile').rules('add', {
            required: true,
            maxlength: 12,
            minlength: 5,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });

        $('#registeration-form #registration-form-password').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax
            }
        });
        $('#registeration-form #registration-form-password-confirm').rules('add', {
            required: true,
            minlength: 8,
            maxlength: 17,
            equalTo: '#registration-form-password',
            messages: {
                required: resources.Global$passwordRequired,
                minlength: resources.Global$passwordMin,
                maxlength: resources.Global$passwordMax,
                equalTo: resources.Global$passwordequalTo
            }
        });
    
        // $('#registeration-form #birthdayPrev').rules('add', {
        //     required: true,
        //     messages: {
        //         required: resources.Global$checkInput
        //     }
        // });

        // $('#registeration-form .jq-dte-inner input').keypress(function (e) {
        //     var regex = new RegExp('^[0-9-]+$');
        //     var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        //     if (regex.test(str)) {
        //         return true;
        //     }

        //     e.preventDefault();
        //     return false;
        // });

        // if ($('#nationality').length > 0) {
        //     $('#nationality').select2({
        //         placeholder: resources.pleaseSelect,
        //         language: {
        //             noResults: function () {
        //                 return resources.labelNoCountry;
        //             }
        //         }
        //     });
        // }

        // $('#registration-form-password-confirm').rules('add', {
        //     required: true,
        //     equalTo: '#registration-form-password',
        //     maxlength: 17,
        //     messages: {
        //         required: resources.Global$passwordRequired,
        //         equalTo: resources.Global$passwordequalTo,
        //         minlength: resources.Global$passwordMin,
        //         maxlength: resources.Global$passwordMax
        //     }
        // });


        errorLabel.registerationButtonEnableMobile('#registeration-form', '#registeration-form #mobile-register-button');
    },

    resetPassword: function () {
        $('.reset-password-form').validate({
            rules: {
                loginEmail: {
                    required: true,
                    email: false,
                    regex: errorLabel.email$regex
                }
            },
            messages: {
                loginEmail: {
                    regex: resources.Global$invalidEmail
                }
            },
            submitHandler: function (gform, e) {
                var form = $(gform);
                e.preventDefault();
                var url = form.attr('action');
                // form.spinner().start();
                $('.reset-password-form').trigger('login:register', e);
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        // form.spinner().stop();
                        if (!data.success) {
                            toastr(data, data.receivedMsgBody);
                        } else if (isMobile) {
                            forgotPasswordSuccess(data.receivedMsgBody);
                        } else {
                            forgotPasswordSuccess(data.receivedMsgBody);
                        }
                    },
                    error: function (data) {
                        form.spinner().stop();
                        toastr(data, data.error);
                    }
                });
                return false;
            }
        });
        errorLabel.forgotPasswordbuttonEnable('#forgot-password-form', '#forgot-password-form #reset-password-email', '#forgot-password-form .js-form-submit');
    },

    clearResetForm: function () {
        $('.modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control').removeClass('is-invalid valid');
            $('.modal-dialog .js-form-submit').addClass('is-disabled');
            $('.modal-dialog label').removeClass('active valid');
        });
    },

    mobileOtpFill: function () {
        $('.singleOtp').first().focus();
        // eslint-disable-next-line consistent-return
        $('.singleOtp').keyup(function ({key}) {
            var len = $(this).val().toString().length;
            var act = $(this).parent().find('.combineOtp');
            var accumulatedValue = '';

            if (["Backspace", "Delete"].includes(key)) {
                $(this).val('');
                $(this).prev().focus();
            } else if (len === 1) {
                $(this).next().focus();
            }

            // eslint-disable-next-line consistent-return
            $('.singleOtp').each(function () {
                var $trim = $(this).val();
                $trim = $.trim($trim);
                accumulatedValue += $trim;
                if ($trim.length !== 0 && accumulatedValue.length === 4) {
                    act.val(accumulatedValue);
                    if (globalRegister == false) {
                        if ($('#registeration-form').length > 0) {
                            $('#registeration-form').submit();
                        }
                        if ($('#partner-register').length > 0) {
                            $('#partner-register').submit();
                        }
                    }
                    return false;
                }
            });
        });
        $(".singleOtp").on("paste", function (event) {
            var pastedData = (event.originalEvent || event).clipboardData.getData('text/plain');
            if (pastedData.length === 4 && /^[0-9]+$/.test(pastedData)) {
                // Extract individual digits
                var digits = pastedData.split("");

                // Populate each input field with one digit
                $(".singleOtp").each(function (index) {
                    if (index < 4) {
                        $(this).val(digits[index]); // Set value
                    }
                });
                $(this).trigger('keyup');
            } else {
                alert("Please paste a valid 4-digit verification code.");
            }
        });
    },

    sendOTPTo: function () {
        $(document).on('click', '.otp-to-sendTo', function () {
            var $this = $(this);
            var $selected = $this.data('type');
            $this.addClass('active').siblings().removeClass('active');
            var otpText = $('.otpSMSText').text();
            if ($selected === 'email') {
                otpText = $('.otpEmailText').text();
            } else {
                otpText = $('.otpSMSText').text();
            }
            $('.js-otpText').text(otpText);
            $this.parent().find('input').val($selected);
        });
    },

    init: function () {
        $('.js-go-register').on('click', function () {
            registerScreen();
        });

        $('.js-existing-user').on('click', function () {
            loginScreen();
        });

        $('.js-cancel-view').on('click', function () {
            goBackScreen($(this));
        });

        $('.js-trigger-forgotpassword').on('click', function () {
            forgetPasswordTrigger();
        });

        $('.resend-password').on('click', function () {
            $('.forgot-password-success').fadeOut(100, function () {
                $('.forgot-password').fadeIn(100);
            });
        });

        $('.js-verifyOtpForm').on('click', function () {
            if ($('#verify-otp').valid()) {
                $('#verify-otp').submit();
            } else {
                var errorMessage = $('#errorMessage').val() || 'Please fill in all details';
                alert(errorMessage);
            }
        });

        $('.js-directRegister').on('click', function () {
            $('#registeration-form').submit();
        });

        $('.js-directRegister-enoc').on('click', function () {
            if ($('#partner-register').valid()) {
                $('#partner-register').submit();
            }
        });

        $('.js-directRegister-partner').on('click', function (e) {
            if ($('#landing-form').valid()) {
                $('#landing-form').submit();
            } else {
                var errorMessage = $('#errorMessage').val() || 'Please fill in all details';
                alert(errorMessage);
            }
        });

        $('.js-confirm-passwords').on('click', function (e) {
            $('#registeration-form-password').submit();
        });

        $('.js-close-modal-box-trigger').on('click', function () {
            closeModalBoxTrigger();
            setTimeout(function () {
                $('.forgot-password-success').hide();
                $('.forgot-password').show();
                $('.forgot-password #reset-password-email').val('');
                $('.forgot-password label').removeClass('active');
                $('.forgot-password #submitEmailButton').addClass('is-disabled');
            }, 500);
        });

        $('.js-box-reveal').on('click', function () {
            $(this).removeClass('close');
            $(this).siblings().addClass('close');
            $(this).siblings().find('#registeration-step-2').hide();
            $(this).siblings().find('#registeration-step-1').show();
        });

        $('.js-registeration-goback-otp').on('click', function () {
            $('#registeration-step-2').fadeOut(50, function () {
                $('#registeration-step-1').fadeIn(100);
                $('#registeration-profile-pic').fadeIn(100);
                $('.nomargi-flex .js-registeration-goback-otp').hide();
                $('.otp-mobile-inputs input').val('');
            });
        });

        $('.js-confirm-passwords-done').on('click', function (e) {
            e.preventDefault();
            location.reload();
        });

        $('.js-password-help').on('click', function () {
            forgetPasswordTriggerDesktop();
        });

        $(document).on('click', '.js-go-back-login', function () {
            $('.forgot-password, .forgot-password-success').fadeOut(100, function () {
                if (isMobile) {
                    $('.login-box').fadeIn(100);
                } else {
                    $('.forgot-password').fadeOut();
                    $(setTimeout(function () {
                        $('.login-box').fadeIn();
                        $('.login-area-wrapper > .js-main-title').fadeIn();
                    }, 400));
                }
                
                $('.login-profile-selection').removeClass('d-none');
            });
        });

        $('.js-view-landing-login, .js-existing-user-login').on('click', function () {
            $('#first-selection-step').addClass('d-none');
            $('#registration-step').addClass('d-none');
            $('#login-step').removeClass('d-none');
        });
        $('.js-view-landing-register, .js-new-register').on('click', function () {
            $('#first-selection-step').addClass('d-none');
            $('#login-step').addClass('d-none');
            $('#registration-step').removeClass('d-none');

        });
        $('body').on('click', '.js-enoc-code', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $parent = $this.closest('.enoc-code');
            var $clone = $parent.clone();
            var $wrapper = $this.closest('.js-enoc-code-wrapper');
            $parent.find('.js-enoc-code').remove();
            $clone.find('input:text').val('').end().appendTo($wrapper);
            $(document).trigger('reinit:materlialize');
        });
        $('body').on('click', '.js-change-language-landing', function (e) {
            e.preventDefault();
            var $this = $(this);
            var currentUrl = window.location.href;
            var lang = $this.data('current-lang') === 'en' ? 'ar' : 'en';
            var url = $(this).data('href');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: lang,
                    action: $('.page').data('action')
                },
                success: function (response) {
                    if (response.success) {
                        window.location.href = response.redirectUrl;
                    } else {
                        window.location.reload();
                    }
                },
                error: function () {
                    window.location.reload();
                }
            });
        });
    }
};